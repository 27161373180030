<template>
  <div class="container-fuild">
    <div class="card">
      <div class="card-body">
        <div class="row mb-2 ">
          <div class="col-12 col-sm-6">
            <div class="row mt-1">
              <div class="col-12 col-sm-6">
                <b-form-group
                  label="เริ่มต้น"
                  label-for="vi-first-name"
                >
                  <DatePicker
                    ref="updateDate"
                    :date="inputs.dateStart"
                    :type="'start'"
                    :status="false"
                    @setDatePicker="onSetDatePicker"
                  />
                </b-form-group>
              </div>
              <div class="col-12 col-sm-6">
                <b-form-group
                  label="สิ้นสุด"
                  label-for="vi-first-name"
                >

                  <DatePicker
                    :date="inputs.dateEnd"
                    :type="'end'"
                    :status="false"
                    @setDatePicker="onSetDatePicker"
                  />
                </b-form-group>

              </div>

            </div>

          </div>
          <div class="col-12 col-sm-6  align-self-center">
            <div
              class="d-flex"
              style="margin-top:5px;"
            >
              <b-button
                :variant="outline[0]"
                :class="{ active: type === 1 }"
                class="border-primary btBetween"
                size="sm"
                @click="onSetbetween(1)"
              >วันนี้
              </b-button>
              <b-button
                :variant="outline[1]"
                :class="{ active: type === 7 }"
                class="border-primary btBetween"
                size="sm"
                @click="onSetbetween(7)"
              >ย้อนหลัง 1 สัปดาห์
              </b-button>
              <b-button
                :variant="outline[2]"
                class="border-primary btBetween"
                :class="{ active: type === 30 }"
                size="sm"
                @click="onSetbetween(30)"
              >ย้อนหลัง 30 วัน
              </b-button>

            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-12 col-sm-4 ">
            <div
              class="card earnings-card bg-primary"
              style="    height: 130px;
"
            >
              <div
                class="card-body"
                style="cursor:pointer"
                @click="onClickrouter"
              >
                <b-media no-body>
                  <b-media-body class="my-auto fzText">
                    <b-card-text
                      class="font-small-3 mb-1"
                      style="color: white;"
                    >
                      ออเดอร์
                    </b-card-text>
                    <div class="d-flex">
                      <h4
                        v-if="dataCard.totalOrder < 1000000"
                        class="font-weight-bolder mb-0  text-truncate mr-1"
                        style="color: white;"
                      >
                        {{ dataCard.totalOrder | toAmount }}
                      </h4>
                      <h4
                        v-if="dataCard.totalOrder > 1000000"
                        class="font-weight-bolder mb-0  text-truncate mr-1"
                        style="color: white;"
                      >
                        {{ dataCard.totalOrder | toAbbreviate }}
                      </h4>
                      <h4 style="color: white;">
                        #
                      </h4>
                    </div>
                    <feather-icon
                      class="icon-bg"
                      :icon="'BookOpenIcon'"
                    />
                  </b-media-body>
                </b-media>          </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 ">
            <div
              class="card earnings-card bg-info"
              style="    height: 130px;
"
            >
              <div class="card-body">
                <b-media no-body>

                  <b-media-body class="my-auto fzText">
                    <b-card-text
                      class="font-small-3 mb-1"
                      style="color: white;"
                    >
                      {{ 'ลูกค้า' }}
                    </b-card-text>
                    <div class="d-flex">
                      <h4
                        v-if="dataCard.totalCustomer < 1000000"
                        class="font-weight-bolder mb-0 text-truncate mr-1"
                        style="color: white;"
                      >
                        {{ dataCard.totalCustomer | toAmount }}
                      </h4>
                      <h4
                        v-if="dataCard.totalCustomer > 1000000"
                        class="font-weight-bolder mb-0 text-truncate mr-1"
                        style="color: white;"
                      >
                        {{ dataCard.totalCustomer | toAbbreviate }}
                      </h4>
                      <h4 style="color: white;">
                        #
                      </h4>
                    </div>

                    <feather-icon
                      class="icon-bg"
                      :icon="'UserPlusIcon'"
                    />
                  </b-media-body>
                </b-media>          </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 ">
            <div
              class="card earnings-card bg-success"
              style="    height: 130px;
"
            >
              <div class="card-body">
                <b-media no-body>

                  <b-media-body class="my-auto fzText">
                    <b-card-text
                      class="font-small-3 mb-1"
                      style="color: white;"
                    >
                      {{ 'ยอดขาย' }}
                    </b-card-text>
                    <div class="d-flex">
                      <h4
                        v-if="dataCard.totalIncome < 1000000"
                        class="font-weight-bolder mb-0 text-truncate mr-1"
                        style="color: white;"
                      >
                        {{ dataCard.totalIncome | toAmount }}
                      </h4>
                      <h4
                        v-if="dataCard.totalIncome > 1000000"
                        class="font-weight-bolder mb-0 text-truncate mr-1"
                        style="color: white;"
                      >
                        {{ dataCard.totalIncome | toAbbreviate }}
                      </h4>
                      <h4 style="color: white;">
                        ฿
                      </h4>
                    </div>

                    <feather-icon
                      class="icon-bg"
                      :icon="'DollarSignIcon'"
                    />
                  </b-media-body>
                </b-media>          </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-12 col-sm-6">
            <div class="row">
              <div class="col-12 col-sm-6">
                <b-form-group
                  label="เดือน"
                  label-for="vi-first-name"
                >
                  <b-form-select
                    v-model="inputs.cartItemBestsellMonth"
                    :options="cartItemBestsellMonth"
                  />
                </b-form-group>
              </div>
              <div class="col-12 col-sm-6">
                <b-form-group
                  label="ปี"
                  label-for="vi-first-name"
                >
                  <b-form-select
                    v-model="inputs.cartItemBestsellYear"
                    :options="cartItemBestsellYear"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <h4
          class="mt-1 text-primary"
          style="cursor:pointer;    text-decoration: underline;
"
          @click="onClickItem"
        >
          สินค้าขายดีเดือน {{ formatYY }} {{ inputs.cartItemBestsellYear }}
        </h4>
        <div class="row">
          <div
            class="col-12"
            style="overflow-x: scroll;"
          >
            <app-echart-bar
              id="chartbestitem"
              class="wdrs"
              :option-data="optionItemOfmonth"
            />

          </div>
        </div>
        <h4 class="mt-2">
          ตัวแทนขายดีเดือน {{ formatYY }} {{ inputs.cartItemBestsellYear }}
        </h4>
        <div class="row mt-1">
          <div
            class="col-12"
            style="overflow-x: scroll;"
          >
            <app-echart-bar
              id="chartbestcus"
              class="wdrs"
              :option-data="optionsCustomerBestsell"
              @onHandleCharts="onHandleCharts"
            />

          </div>
        </div>
        <h4
          class="mt-1"
        >
          ช่องทางขายดีเดือน {{ formatYY }} {{ inputs.cartItemBestsellYear }}
        </h4>
        <div class="row">
          <div
            class="col-12"
            style="overflow-x: scroll;"
          >
            <app-echart-bar
              id="chartbestitem"
              class="wdrs"
              :option-data="optionsChanelBestsell"
            />

          </div>
        </div>
        <h4 class="mt-3">
          ยอดขายของเดือนรายปี
        </h4>
        <div class="row ">
          <div class="col-12 col-sm-3 col-xl-3">
            <b-form-group
              label="ปี"
              label-for="vi-first-name"
            >
              <b-form-select
                v-model="inputs.cartIncomeYear"
                :options="cartItemBestsellYear"
              />
            </b-form-group>
          </div>
        </div>

        <b-row>
          <b-col cols="12">
            <div
              class="col-12"
              style="overflow-x: scroll;"
            >
              <app-echart-bar
                class="wdrs"
                :option-data="option"
              />
            </div>
          </b-col>
        </b-row>

      </div>

    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect

} from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'
import moment from 'moment'

import momentimezone from 'moment-timezone'
import DatePicker from '@/views/components/production/datePicker.vue'
import router from '@/router'

export default defineComponent({
  name: 'dashboard',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BInputGroupPrepend,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BInputGroup,
    DatePicker,
    BFormSelect,
    AppEchartBar,
    moment
  },
  created () {
    this.loader()
  },
  computed: {
    formatYY () {
      const monthYear = momentimezone.tz(`${this.inputs.cartItemBestsellYear}-${this.inputs.cartItemBestsellMonth}-01`, 'Asia/Bangkok').locale('th').format('LLL').split(' ')
      return monthYear[1]
    }
  },
  watch: {
    type (val) {
      for (let i = 0; i < 3; i += 1) {
        this.outline[i] = 'outline-primary'
      }
      if (val === 1) {
        this.outline[0] = 'primary'
      } else if (val === 7) {
        this.outline[1] = 'primary'
      } else if (val === 30) {
        this.outline[2] = 'primary'
      }
    },
    'inputs.dateStart': {
      handler (val) {
        this.getDataSumDate()
      }
    },
    'inputs.dateEnd': {
      handler (val) {
        this.getDataSumDate()
      }
    },
    'inputs.cartItemBestsellMonth': {
      handler (val) {
        this.getDataCartItemBestsell()
      }
    },
    'inputs.cartItemBestsellYear': {
      handler (val) {
        this.getDataCartItemBestsell()
      }
    },
    'inputs.cartIncomeYear': {
      handler (val) {
        this.getDataCartIncome()
      }
    }
  },
  methods: {
    onClickItem () {
      const startDate = `${this.inputs.cartItemBestsellYear}-${this.inputs.cartItemBestsellMonth}-01`
      const endDate = moment().endOf('month').format(`${this.inputs.cartItemBestsellYear}-${this.inputs.cartItemBestsellMonth}-DD`)
      this.$router.push({ path: `/reportproduct/${startDate}/${endDate}` })
    },

    onHandleCharts  (val) {
      const startDate = `${this.inputs.cartItemBestsellYear}-${this.inputs.cartItemBestsellMonth}-01`
      const endDate = moment().endOf('month').format(`${this.inputs.cartItemBestsellYear}-${this.inputs.cartItemBestsellMonth}-DD`)
      const id = this.optionsCustomerBestsell.yAxis[0].data[val.dataIndex].name
      this.$router.push({ path: `/customer/order/${id}/${startDate}/${endDate}` })
    },
    onClickrouter () {
      router.push(`/reportorder/${this.inputs.dateStart}/${this.inputs.dateEnd}`)
    },
    onSetbetween (val) {
      if (val === 1) {
        this.inputs.dateStart = moment().format('YYYY-MM-DD')
      } else {
        this.inputs.dateStart = moment().subtract(val, 'days').format('YYYY-MM-DD')
      }
      this.type = val
      this.$refs.updateDate.setdate(this.inputs.dateStart)
    },
    getDataSumDate () {
      if (!this.onloadCheck) return
      this.$store.dispatch('dashboard/getDataSum', { startDate: this.inputs.dateStart, endDate: this.inputs.dateEnd }).then(res => {
        if (res.status === 200) {
          console.log(res.data)
          this.dataCard.totalCustomer = res.data.totalCustomer
          this.dataCard.totalIncome = res.data.totalIncome
          this.dataCard.totalOrder = res.data.totalOrder
        }
      })
    },
    getDataCartItemBestsell () {
      if (!this.onloadCheck) return
      const startDate = `${this.inputs.cartItemBestsellYear}-${this.inputs.cartItemBestsellMonth}-01`
      const endDate = `${this.inputs.cartItemBestsellYear}-${this.inputs.cartItemBestsellMonth}-31`
      this.$store.dispatch('dashboard/getItemBestSellofMonth', { startDate, endDate }).then(res => {
        this.optionItemOfmonth.yAxis[0].data = []
        this.optionItemOfmonth.series[0].data = []
        for (let i = res.data.items.length - 1; i >= 0; i--) {
          this.optionItemOfmonth.yAxis[0].data.push(res.data.items[i].product[0].name)
          this.optionItemOfmonth.series[0].data.push(res.data.items[i].total.$numberDecimal * 1)
        }
      })
      this.$store.dispatch('dashboard/getBestSellCustomer', { startDate, endDate }).then(res => {
        this.optionsCustomerBestsell.yAxis[0].data = []
        this.optionsCustomerBestsell.series[0].data = []
        for (let i = res.data.items.length - 1; i >= 0; i--) {
          this.optionsCustomerBestsell.yAxis[0].data.push({
            name: res.data.items[i].customer[0]._id,
            value: `${res.data.items[i].customer[0].firstName} ${res.data.items[i].customer[0].lastName}`
          })
          this.optionsCustomerBestsell.series[0].data.push(res.data.items[i].total.$numberDecimal * 1)
        }
      })
      this.$store.dispatch('dashboard/getBestChannel', { startDate, endDate }).then(res => {
        this.optionsChanelBestsell.yAxis[0].data = []
        this.optionsChanelBestsell.series[0].data = []
        for (let i = res.data.item.length - 1; i >= 0; i--) {
          this.optionsChanelBestsell.yAxis[0].data.push({
            name: res.data.item[i].payment[0]._id,
            value: `${res.data.item[i].payment[0].name}`
          })
          const newData = {
            value: res.data.item[i].totalPrice.$numberDecimal * 1,
            name: res.data.item[i].payment.name,
            value1: res.data.item[i].totalOrder
          }
          this.optionsChanelBestsell.series[0].data.push(newData)
        }
      })
    },
    getDataCartIncome () {
      if (!this.onloadCheck) return
      this.$store.dispatch('dashboard/getIncomeOfYear', { year: this.inputs.cartIncomeYear }).then(res => {
        this.option.series[0].data = []
        res.data.forEach(data => {
          this.option.series[0].data.push(data.income.$numberDecimal * 1)
        })
      })
    },
    onSetDatePicker (val) {
      if (val.type === 'start') { this.inputs.dateStart = String(val.val) } else { this.inputs.dateEnd = String(val.val) }
    },
    async loader () {
      await this.lander()
      this.onloadCheck = true
      this.getDataCartItemBestsell()
      this.getDataSumDate()
      this.getDataCartIncome()
    },
    lander () {
      this.onloadCheck = false
      this.cartItemBestsellYear = []
      const today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = today.getFullYear()
      this.inputs.cartIncomeYear = yyyy
      this.inputs.cartItemBestsellYear = yyyy
      this.inputs.cartItemBestsellMonth = mm

      this.inputs.dateStart = `${yyyy}-${mm}-${dd}`
      this.inputs.dateEnd = `${yyyy}-${mm}-${dd}`
      for (let i = 0; i < 5; i++) {
        this.cartItemBestsellYear.push({
          text: yyyy - i,
          value: yyyy - i
        })
      }
    }

  },
  data () {
    return {
      type: 1,
      outline: ['primary', 'outline-primary', 'outline-primary'],
      IsopenModal: false,
      onloadCheck: false,
      dataCard: {
        totalCustomer: 0,
        totalIncome: 0,
        totalOrder: 0

      },
      inputs: {
        dateStart: '1999-01-01',
        dateEnd: '1999-01-01',
        cartItemBestsellMonth: '01',
        cartItemBestsellYear: '',
        cartIncomeYear: ''

      },
      cartItemBestsellYear: [],
      cartItemBestsellMonth: [{
        value: '01', text: 'ม.ค'
      },
      {
        value: '02', text: 'ก.พ'
      }, {
        value: '03', text: 'มี.ค'
      },
      {
        value: '04', text: 'เม.ย'
      }, {
        value: '05', text: 'พ.ค.'
      },
      {
        value: '06', text: 'มิ.ย.'
      }, {
        value: '07', text: 'ก.ค.'
      },
      {
        value: '08', text: 'ส.ค.'
      }, {
        value: '09', text: 'ก.ย.'
      },
      {
        value: '10', text: 'ต.ค.'
      }, {
        value: '11', text: 'พ.ย.'
      },
      {
        value: '12', text: 'ธ.ค.'
      }],
      barChart: {
        series: [
          {
            data: [700, 350, 480, 600, 210, 550, 150]
          }
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false
            }
          },
          colors: '#03CFE7',
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '30%',
              endingShape: 'rounded'
            }
          },
          grid: {
            xaxis: {
              lines: {
                show: false
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: ['MON, 11', 'THU, 14', 'FRI, 15', 'MON, 18', 'WED, 20', 'FRI, 21', 'MON, 23']
          },
          yaxis: {
            // opposite: isRtl,
          }
        }
      },
      optionsCustomerBestsell: {
        onClick: this.ClickHandler,
        xAxis: [
          {
            type: 'value'
          }
        ],
        yAxis: [
          {
            type: 'category',
            data: [],
            splitLine: { show: false },
            axisLabel: {
              formatter: val => (val.length > 15 ? `${val.substring(0, 10)}...` : val)
            }
          }

        ],
        grid: {
          left: '100px',
          right: '30px',
          bottom: '30px'
        },
        series: [
          {
            name: 'ยอดขาย/บาท',
            type: 'bar',
            stack: 'advertising',
            data: [],
            color: ['#03CFE7'],
            barWidth: '20px',
            itemStyle: {
              barBorderRadius: [0, 10, 10, 0]
            }
          }
        ]

      },
      optionsChanelBestsell: {
        onClick: this.ClickHandler,
        xAxis: [
          {
            type: 'value'
          }
        ],
        yAxis: [
          {
            type: 'category',
            data: [],
            splitLine: { show: false },
            axisLabel: {
              formatter: val => (val.length > 15 ? `${val.substring(0, 10)}...` : val)
            }
          }

        ],
        grid: {
          left: '100px',
          right: '30px',
          bottom: '30px'
        },
        series: [
          {
            name: 'ยอดขาย/บาท',
            type: 'bar',
            stack: 'advertising',
            data: [],
            color: ['#FDDC00'],
            barWidth: '20px',
            itemStyle: {
              barBorderRadius: [0, 10, 10, 0]
            },
            tooltip: {
              trigger: 'item',
              formatter (params) {
                const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 0 })
                return `${params.name} <br />
              - ยอดขาย/บาท: ${formatter.format(params.data.value)} <br />
              - ยอดขายจำนวน/ออเดอร์: ${formatter.format(params.data.value1)} `
              }
            }
          }
        ]

      },

      optionItemOfmonth: {
        xAxis: [
          {
            type: 'value'
          }
        ],
        yAxis: [
          {
            type: 'category',
            data: [],
            splitLine: { show: false },
            axisLabel: {
              formatter: val => (val.length > 15 ? `${val.substring(0, 10)}...` : val)
            }
          }

        ],
        grid: {
          left: '1%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        series: [
          {
            name: 'ยอดขาย/ชิ้น',
            type: 'bar',
            stack: 'advertising',
            data: [],
            barWidth: '20px',
            itemStyle: {
              barBorderRadius: [0, 10, 10, 0]
            }
          }
        ]
      },
      option: {
        responsive: true,
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: ['ม.ค', 'ก.พ', 'มี.ค', 'เม.ย', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            // max: 200000,
            position: 'left',
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        grid: {
          left: '70px',
          right: '30px',
          bottom: '30px'
        },
        series: [
          {
            name: 'ยอดขาย/เดือน',
            type: 'bar',
            stack: 'advertising',
            data: [],
            barWidth: '20px',
            color: [
              '#29C76F']
            // barMinWidth: '12px'
          }
        ]
      }

    }
  }
})
</script>
<style scoped>
.btBetween{
  width: 130px;height: 2.5rem !important;
  height: 25px;margin-right: 3px;
}
@media screen and (max-width: 900px) and (min-width: 600px) {
  .fzText h4{
font-size: 1.5rem !important;
  }
    .fzText p{
font-size: 1.0rem !important;
  }
}
@media screen and (min-width: 900px) , (max-width: 600px) {
  .fzText h4{
font-size: 3.0rem !important;
  }
    .fzText p{
font-size: 1.5rem !important;
  }
}

.wdrs{
        width: 100% !important;
        overflow-x: scroll;
        min-width: 900px !important;
}

.icon-bg {
    position: absolute;
    right: -14px;
    top: 6px;
    opacity: 0.4;
    transition: all 0.3s ease;
    width: 120px;
    height: 120px;
    color: white;;
}
</style>
